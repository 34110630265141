<template>
  <div>
    <div class="breadcrumb">
      <h1>Registro de Ventas</h1>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3">
                <button
                  class="btn th-custom-color px-4 rounded"
                  @click="irACrearFactura"
                  :disabled="!hasPermisionNuevoVentas"
                >
                  Nuevo
                </button>
              </div>
              <div class="col-sm-3 offset-sm-6">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input type="checkbox" id="filter"
                     :checked="estado"
                     v-model="estado"
                     @change="listarRegistros">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select v-model="por_pagina"
                   :options="pagina_opciones"
                   @change="listarRegistros">
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control"
                     v-model="busqueda"
                     autocomplete="off"
                     placeholder="Buscar"
                     @keyup="filtrarBusqueda">
                  </div>
                </div>
              </div>
            </div>
            <b-table
              class="table table-striped table-hover"
              :fields="headLabel" :items="listaTabla"
              :busy="isLoading"
              :per-page="por_pagina">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template #cell(total)="data">
                {{data.item.total | numeral('0,0.00')}} - {{ data.item.simbolo }}
              </template>
              <template #cell(forma_pago)="data">
                <span v-if="data.item.forma_pago==='Contado'
                         || data.item.forma_pago==='Ajuste'
                         || data.item.forma_pago==='Valor-0'"
                  class="text-success">
                  {{data.item.forma_pago}}
                </span>
                <span v-else-if="data.item.forma_pago==='Credito'" class="text-danger">
                  {{data.item.forma_pago}}
                </span>
              </template>
              <template v-if="estado" #cell(editar)="items">
                <!-- Validar que no permita modificar ventas con modalidades en linea -->
                <i
                  v-if="(items.item.modalidad_venta != ModalidadComputarizadaEnLinea && items.item.modalidad_venta != ModalidadElectronicaEnLinea)"
                  @click="onClickEditarIcon(items.item)"
                  class="nav-icon i-Pen-5 font-weight-bold"
                  :class="hasPermisionModificarVentas? 'text-success':''"
                  style="cursor: pointer"
                ></i>
                <i
                  v-else
                  class="nav-icon i-Pen-5 font-weight-bold"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(ver)>
                <i
                  class="nav-icon i-Eye font-weight-bold"
                  :class="hasPermisionVerVentas? 'text-warning':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(imprimir)="data">
                <i
                  class="nav-icon i-File-Download font-weight-bold"
                  :class="hasPermisionImprimirVentas? 'text-info':''"
                  style="cursor: pointer"
                  @click="verPDF(data.item)">
                </i>
              </template>
              <template v-if="estado" #cell(eliminar)="items">
                <i
                  @click="onClickEliminarIcon(items.item)"
                  class="nav-icon i-Close-Window font-weight-bold"
                  :class="hasPermisionEliminarVentas? 'text-danger':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(tipo)="data">
                <img src="@/assets/images/Artboard-38.png"
                  v-if="data.item.tipo === 'VM'"
                  width="32px"
                  alt="Facturación tercero"
                  title="Facturación Tercero" />
              </template>
              <template #cell(asistencia)="data">
                <button class="btn btn-warning py-1 px-3"
                v-if="data.item.turno"
                @click="openModalAsistencia(data.item)"> Turno {{ data.item.turno }} </button>
                <button class="btn btn-secondary"
                v-else
                style="width: 100%; height:30px;"></button>
                <!-- <ModalTurno
                  v-if="data.item.turno"
                  :id="data.item.id"
                  :key="data.item.id"
                  :editMode="false"
                  :medicoSelected="data.item.medico_selected"
                  :turnoFromEdit="1"
                  :fechaTurnoFromEdit="data.item.fecha_turno"
                  :horaTurnoFromEdit="'09:03:00'"
                  :numeroTicketFromEdit="1"
                  :enableButtonTurno="false"
                />
                <button v-else class="btn px-2 btn-btn-secondary"
                style="width: 100%; height:30px;">
                </button> -->
              </template>
            </b-table>
            <b-modal
              id="modal-eliminar"
              :title="'Seguro que quieres eliminar la Factura - N° '+selectedNro+'?'"
              @show="motivoEliminacionSelected = null"
              hide-footer
            >
              <div class="row mb-2">
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                    Eliminar
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-outline-dark"
                  @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
                </div>
              </div>
              <div class="w-10">
                <div class="form-group row">
                  <label for="motivo" class="col-sm-2 col-form-label text-right">Motivo:</label>
                  <div class="col-sm-7 pl-0">
                    <multiselect2
                      v-model="motivoEliminacionSelected"
                      track-by="id"
                      label="descripcion"
                      placeholder="Ingrese el motivo"
                      :options="motivoEliminacionComputed"
                      :allow-empty="false"
                      :searchable="true"
                    ></multiselect2>
                  </div>
                </div>
              </div>
            </b-modal>
            <b-modal id="modal-infoMedico"
              :title="'ACCION NO PERMITIDA'"
              hide-footer
            >
              <template #modal-title>
                <span class="text-danger">ACCION NO PERMITIDA!</span>
              </template>
              <div class="row">
                <div class="col-sm-11 pl-3">
                  <p>Existen instancias relacionadas. A continuación se detallan:</p>
                </div>
                <div class="col-sm-12">
                  <b-table class="table table-striped table-hover"
                    :fields="fieldsInfo"
                    :items="listaPagoMedico"
                  >
                    <template #thead-top>
                      <b-tr>
                        <b-th colspan="3" class="text-center py-0">Pago - Medicos</b-th>
                      </b-tr>
                    </template>
                  </b-table>
                </div>
              </div>
            </b-modal>
            <b-modal id="modal-infoSeguro"
              :title="'ACCION NO PERMITIDA'"
              hide-footer
            >
              <template #modal-title>
                <span class="text-danger">ACCION NO PERMITIDA!</span>
              </template>
              <div class="row">
                <div class="col-sm-11 pl-3">
                  <p>Existen instancias relacionadas. A continuación se detallan:</p>
                </div>
                <div class="col-sm-12">
                  <b-table class="table table-striped table-hover"
                    :fields="fieldsInfo"
                    :items="listaPagoSeguro"
                  >
                    <template #thead-top>
                      <b-tr>
                        <b-th colspan="3" class="text-center py-0">Pago - Seguro</b-th>
                      </b-tr>
                    </template>
                  </b-table>
                </div>
              </div>
            </b-modal>
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  @input="listarRegistros"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pdf
      v-model="modalPrint"
      :numeroVenta="selectedNro"
      :optionsPDF="options_pdf"
      :tipoOrigen="tipoOrigen"
      :tipoFacturas="tipoFacturas"
      :isFacturacionTercero="facturacionTerceroModal"
      :isfacturacionTratamiento="facturacionConTratamiento"
      :numeroTratamiento="numeroTratamientoSelected"
    />
    <asistencia
      v-if="modalAsistencia === true"
      v-model="modalAsistencia"
      :itemSeleccionado="itemSeleccionado"
    />
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { MODALIDAD_FACTURACION } from '@/utils/globalConventions';
import Multiselect2 from '@/components/util/vue-multiselect2';
import pdf, { PDF } from '../components/util/pdf/modalPDF.vue';
import asistencia from '../components/Asistencia.vue';
// import ModalTurno from '../components/ModalTurno.vue';

export default {
  name: 'index',
  components: {
    pdf,
    asistencia,
    // ModalTurno,
    Multiselect2,
  },
  created() {
    this.listarRegistros();
  },
  data() {
    return {
      isLoading: true,
      estado: true,
      busqueda: null,
      pagina_actual: 1,
      por_pagina: 100,
      pagina_opciones: [50, 100],
      fields: [
        {
          key: 'id', label: '', thClass: 'd-none', tdClass: 'd-none', class: 'text-center', thStyle: { width: '0%' },
        },
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'ver', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'imprimir', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '7%' }, sortable: true,
        },
        {
          key: 'cliente', label: 'Paciente', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'medico', label: 'Medico', class: 'text-center', thClass: 'th-custom-color', thStyle: { width: '14%' }, sortable: true,
        },
        {
          key: 'total', label: 'Importe', thClass: 'th-custom-color', thStyle: { width: '12%' }, sortable: true,
        },
        {
          key: 'forma_pago', label: 'Pago', thClass: 'th-custom-color', thStyle: { width: '6%' }, sortable: true,
        },
        {
          key: 'tipo', label: '', class: 'px-2', thClass: 'th-custom-color', thStyle: { width: '3%' }, sortable: false,
        },
        {
          key: 'numero_factura', label: 'N° Factura', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
        },
        {
          key: 'asistencia', label: 'Asistencia', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
        },
        {
          key: 'usuario', label: 'Usuario', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '13%' }, sortable: true,
        },
      ],
      listaTabla: [],
      totalFilas: 0,
      delayTimeout: null,
      selectedId: null,
      selectedNro: null,
      options_pdf: [],
      modalPrint: false,
      tipoOrigen: 'VC',
      tipoFacturas: [],
      facturacionTerceroModal: false,
      facturacionConTratamiento: false,
      numeroTratamientoSelected: '',
      fieldsInfo: [
        {
          key: 'numero', label: 'Id', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thStyle: { width: '35%' },
        },
        {
          key: 'nombre', label: 'Usuario', class: 'text-center', thStyle: { width: '50%' },
        },
      ],
      asistencias: [
        {
          nombre: 'Nuevo',
          color: 'bg-secondary',
        },
        {
          nombre: 'Pendiente',
          color: 'bg-warning',
        },
        {
          nombre: 'Atendido',
          color: 'bg-success',
        },
        {
          nombre: 'Sin Atender',
          color: 'bg-danger',
        },
      ],
      listaPagoMedico: [],
      listaPagoSeguro: [],
      modalAsistencia: false,
      itemSeleccionado: null,
      motivoEliminacionSelected: null,
      motivoEliminacions: [],
      listaMotivosAnulacionMedico: [],
    };
  },
  methods: {
    irACrearFactura() {
      if (this.$route.name !== 'facturacion-create') {
        this.$router.push({ name: 'facturacion-create' });
      }
    },
    async onClickEditarIcon(item) {
      if (this.hasPermisionModificarVentas) {
        await this.buscarRestriccionesVenta(item);
        if (this.listaPagoMedico.length > 0) {
          this.$bvModal.show('modal-infoMedico');
        } else if (this.listaPagoSeguro.length > 0) {
          this.$bvModal.show('modal-infoSeguro');
        } else if (this.$route.name !== 'facturacion-edit') {
          this.$router.push({ name: 'facturacion-edit', params: { id: this.encrypt(item.id) } });
        }
      }
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarRegistros, DELAY);
    },
    async listarRegistros() {
      this.isLoading = true;
      try {
        const REQUEST = {
          state: this.estado,
          filterKey: this.busqueda,
          page: this.pagina_actual,
          per_page: this.por_pagina,
        };
        const RESPONSE = await axios.get('/clinic/invoicing', {
          params: REQUEST,
        });
        this.totalFilas = RESPONSE.data.data.ventas.total;
        this.listaTabla = RESPONSE.data.data.ventas.data;
        this.motivoEliminacions = RESPONSE.data.data.motivo_anulacions;
        this.formaDataListaTabla();
        this.tipoFacturas = RESPONSE.data.data.tipo_facturas;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    formaDataListaTabla() {
      this.listaTabla.forEach((el, index) => {
        const MEDICO_SELECTED = {};
        MEDICO_SELECTED.id = this.listaTabla[index].medico_turno_id;
        MEDICO_SELECTED.nombre = this.listaTabla[index].medico_turno;
        this.listaTabla[index].medico_selected = MEDICO_SELECTED;
      });
    },
    async buscarRestriccionesVenta(item) {
      this.listaPagoMedico = [];
      this.listaPagoSeguro = [];
      this.listaMotivosAnulacionMedico = [];
      try {
        const RESPONSE = await axios.get(`/clinic/invoicing/verif_pago/${item.id}`);
        this.listaPagoMedico = RESPONSE.data.data.pago_medico;
        this.listaPagoSeguro = RESPONSE.data.data.pago_seguro;
        this.listaMotivosAnulacionMedico = RESPONSE.data.data.siat_motivo_anulacion;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async onClickEliminarIcon(item) {
      if (this.hasPermisionEliminarVentas) {
        this.setItems(item);
        await this.buscarRestriccionesVenta(item);
        if (this.listaPagoMedico.length > 0) {
          this.$bvModal.show('modal-infoMedico');
        } else if (this.listaPagoSeguro.length > 0) {
          this.$bvModal.show('modal-infoSeguro');
        } else {
          this.$bvModal.show('modal-eliminar');
        }
      }
    },
    eliminarRegistroLocalmente() {
      this.listaTabla = this.listaTabla.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async deleteVentaFacturador(venta) {
      if (this.motivoEliminacionSelected === null) {
        util.showNotify('Debe seleccionar el motivo para anular una factura', 'warn');
        return;
      }
      try {
        if (this.extrafacturaElectronica) {
          const data = {
            siat_motivoanulacion_id: this.motivoEliminacionSelected ? this.motivoEliminacionSelected.id : '',
            origen: venta.medico_id ? 2 : 1, // 1: ERP, 2: MEDICO
            medico_id: venta.medico_id,
          };
          await axios.post(`synFactura/facturation/delete/${venta.siat_venta_id}`, data);
        } else {
          const msgAlert = 'Usted no cuenta con los privilegios de factura electronica para poder realizar esta accion';
          util.showNotify(msgAlert, 'warn');
          return;
        }
        await this.deleteVentaERP(venta);
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    async deleteVentaERP(venta) {
      try {
        this.eliminarRegistroLocalmente();

        let siatMotivoAnulacionId = '';
        let siatDecripcionAnulacion = '';
        if (
          parseInt(venta.modalidad_venta, 10) === this.ModalidadComputarizadaEnLinea
          || parseInt(venta.modalidad_venta, 10) === this.ModalidadElectronicaEnLinea
        ) {
          siatMotivoAnulacionId = this.motivoEliminacionSelected.id;
          siatDecripcionAnulacion = this.motivoEliminacionSelected.descripcion;
        }

        const REQUEST = {
          siat_motivo_anulacion_id: siatMotivoAnulacionId,
          siat_descripcion_anulacion: siatDecripcionAnulacion,
        };
        const RESPONSE = await axios.delete(`/clinic/invoicing/${this.selectedId}`, { data: REQUEST });
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarRegistros();
      }
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');

      const Venta = this.listaTabla.find((item) => item.id === this.selectedId);
      if (
        parseInt(Venta.modalidad_venta, 10) === this.ModalidadComputarizadaEnLinea
        || parseInt(Venta.modalidad_venta, 10) === this.ModalidadElectronicaEnLinea
      ) {
        await this.deleteVentaFacturador(Venta);
      } else {
        await this.deleteVentaERP(Venta);
      }
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    encrypt(id) {
      return btoa(id);
    },
    async verPDF(venta) {
      if (this.hasPermisionImprimirVentas) {
        this.selectedNro = venta.numero;
        const notComputarizado = !venta.numero_autorizacion || (venta.numero_autorizacion && venta.numero_factura.charAt(0) === 'M');
        let typeFactura = PDF.FACTURA;
        if (parseInt(venta.modalidad_venta, 10) === this.ModalidadComputarizadaEnLinea) {
          typeFactura = PDF.COMPUTARIZADA_EN_LINEA;
        }
        if (parseInt(venta.modalidad_venta, 10) === this.ModalidadElectronicaEnLinea) {
          typeFactura = PDF.ELECTRONICA_EN_LINEA;
        }
        let tratamiento = 0; // boolean
        let tratamientoNumero = null;
        let numeroTicket = 0; // boolean
        try {
          const RESPONSE = await axios.get(`/clinic/invoicing/has_trat_turno/${venta.id}`);
          tratamiento = RESPONSE.data.data.trat_turno.tratamiento;
          tratamientoNumero = RESPONSE.data.data.trat_turno.tratamiento_numero;
          numeroTicket = RESPONSE.data.data.trat_turno.numero_ticket;
        } catch (error) {
          util.showNotify(error.response.data.message, 'error');
        }
        if (tratamiento === 1) {
          this.facturacionConTratamiento = true;
          this.numeroTratamientoSelected = tratamientoNumero;
          this.facturacionTerceroModal = false;
          this.options_pdf = [
            {
              type: PDF.NOTA,
              url: `/clinic/invoicing/print/note/${venta.id}`,
            },
            {
              type: typeFactura,
              disabled: notComputarizado && typeFactura === PDF.FACTURA,
              url: `/ventaservicios/pdf/factura/${venta.id}`,
            },
            {
              type: PDF.LIBRODIARIO,
              url: `/ventaservicios/${venta.id}/pdf-comprobante`,
            },
          ];
          if (numeroTicket) {
            const turno = {
              type: PDF.TURNO,
              url: `/clinic/invoicing/turno/ticket/${venta.id}`,
            };
            this.options_pdf.push(turno);
          }
        } else if (venta.tipo === 'VM') {
          this.facturacionConTratamiento = false;
          this.numeroTratamientoSelected = '';
          this.facturacionTerceroModal = true;
          this.options_pdf = [
            {
              type: PDF.NOTA,
              url: `/clinic/invoicing/print/note/${venta.id}`,
            },
            {
              type: typeFactura,
              disabled: notComputarizado && typeFactura === PDF.FACTURA,
              url: `/ventaservicios/pdf/factura/${venta.id}`,
            },
            {
              type: PDF.URL_SIAT,
              disabled: (parseInt(venta.modalidad_venta, 10) !== this.ModalidadComputarizadaEnLinea && parseInt(venta.modalidad_venta, 10) !== this.ModalidadElectronicaEnLinea),
              url: `/synFactura/factura/url_siat/${venta.id}`,
            },
          ];
          if (numeroTicket) {
            const turno = {
              type: PDF.TURNO,
              url: `/clinic/invoicing/turno/ticket/${venta.id}`,
            };
            this.options_pdf.push(turno);
          }
        } else {
          this.facturacionConTratamiento = false;
          this.numeroTratamientoSelected = '';
          this.facturacionTerceroModal = false;
          this.options_pdf = [
            {
              type: PDF.NOTA,
              url: `/clinic/invoicing/print/note/${venta.id}`,
            },
            {
              type: typeFactura,
              disabled: notComputarizado && typeFactura === PDF.FACTURA,
              url: `/ventaservicios/pdf/factura/${venta.id}`,
            },
            {
              type: PDF.LIBRODIARIO,
              url: `/ventaservicios/${venta.id}/pdf-comprobante`,
            },
            {
              type: PDF.URL_SIAT,
              disabled: (parseInt(venta.modalidad_venta, 10) !== this.ModalidadComputarizadaEnLinea && parseInt(venta.modalidad_venta, 10) !== this.ModalidadElectronicaEnLinea),
              url: `/synFactura/factura/url_siat/${venta.id}`,
            },
          ];
          if (numeroTicket) {
            const turno = {
              type: PDF.TURNO,
              url: `/clinic/invoicing/turno/ticket/${venta.id}`,
            };
            this.options_pdf.push(turno);
          }
        }
        this.$emit('resetPrint', notComputarizado);
        this.modalPrint = true;
      }
    },
    openModalAsistencia(item) {
      if (item.turno) {
        this.itemSeleccionado = item;
        this.modalAsistencia = true;
      }
    },
  },
  computed:
  {
    ...mapFields('main', [
      'extrafacturaElectronica',
    ]),
    ...mapGetters('main', [
      'hasPermisionNuevoVentas',
      'hasPermisionModificarVentas',
      'hasPermisionVerVentas',
      'hasPermisionEliminarVentas',
      'hasPermisionImprimirVentas',
      'hasPermissionMedicoTercero',
    ]),
    headLabel() {
      const label = this.fields;
      if (!this.hasPermissionMedicoTercero) label.splice(8, 1);
      return label;
    },
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    motivoEliminacionComputed() {
      if (this.listaMotivosAnulacionMedico.length > 0) {
        return this.listaMotivosAnulacionMedico;
      }
      return this.motivoEliminacions;
    },
    ModalidadComputarizadaEnLinea() {
      return MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA;
    },
    ModalidadElectronicaEnLinea() {
      return MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA;
    },
  },
};
</script>

<style scoped>
.btn-yellow{
  background: yellow;
  color: black;
}
</style>
