<template>
  <div>
    <b-modal id="modal-asistencia" size="md"
      title="Asistencia"
      :visible="visible"
      hide-footer
      @change="$emit('change', $event)"
    >
      <template #modal-header="{ close }">
      <div class="container row">
        <div class="col-sm-6 pl-0">
          <h5 class="modal-title">Programar Atención</h5>
        </div>
        <div class="col-sm-6 pl-0">
          <button
            class="btn mr-3 btn-success"
          >Turno 1</button>
        </div>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="close()"
      >×</button>
      </template>
      <div class="row mb-3">
        <div class="col-sm-12">
          <b>
            <label class="col-form-label" v-if="itemSeleccionado.medico">
              DR. {{ itemSeleccionado.medico }}
          </label>
          </b>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-3 text-right">
          <label class="col-form-label">Fecha:</label>
        </div>
        <div class="col-sm-3 px-0">
          <date-picker
            class="col-sm-12 px-0"
            v-model="inputFecha"
            lang="es"
            format="DD/MM/YYYY"
            type="date"
            value-type="date"
          ></date-picker>
        </div>
        <div class="col-sm-3 px-1">
          <input
            type="text"
            class="form-control"
            disabled="true"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 text-right">
          <label class="col-form-label">Horario:</label>
        </div>
        <div class="col-sm-4 pl-0">
          <multiselect
            v-model="horarioSelected"
            :options="listaHorarios"
            placeholder=""
            selectedLabel=""
            label="hora"
            track-by="ticket"
            select-label=""
            deselect-label="X"
          >
            <span slot="caret" v-if="!(horarioSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">No trabaja</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.ticket}} - {{props.option.hora}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.ticket}} - {{props.option.hora}}</small>
            </template>
          </multiselect>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  components: {
    Multiselect,
    DatePicker,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemSeleccionado: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputFecha: new Date(),
      horarioSelected: null,
      listaHorarios: [],
    };
  },
};
</script>
