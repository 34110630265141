<template>
  <div>
    <b-modal
      :visible="visible"
      :title="'Seleccione para Ver - N° '+numeroVenta+''"
      :size="tipoOrigen === ''? 'sm': 'md'"
      hide-footer
      @change="$emit('change', $event)">
      <div class="row" v-if="isfacturacionTratamiento">
        <div class="col-sm-12 text-right">
          Tratamiento N° {{numeroTratamiento}}
        </div>
      </div>
      <div class="row mt-4">
        <div :class="[tipoOrigen === ''? 'col-sm-12': 'col-sm-6']">
          <div class="col-sm-12 mb-3" v-for="option in optionsPDF" :key="option.id">
            <button @click="download(option)"
              :class="['btn', 'col-sm-9', option.disabled ? 'gris' : getClassByType(option.type)]"
              :disabled="option.disabled"
            >
              {{ getTextByType(option.type) }}
            </button>
            <button
              v-if="option.type !== getUrlSiat"
              class="btn col-sm-2 px-0 py-1 offset-sm-1 btn-secondary"
              :disabled="option.disabled"
              @click="impresionRapida(option.url)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-printer"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                <path
                  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                  2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                  2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                  2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                  2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                  1 0 0 1 1 1z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-check" v-if="!isFacturacionTercero">
            <input class="form-check-input" type="checkbox" v-model="sinLogo">
            <label class="form-check-label">Sin Logo</label>
          </div>
          <div class="form-check" v-if="!isFacturacionTercero">
            <input class="form-check-input" type="checkbox" v-model="sinImporte">
            <label class="form-check-label">Sin Importes</label>
          </div>
          <div class="form-check" v-if="isfacturacionTratamiento">
            <input class="form-check-input" type="checkbox" v-model="tratamientoSinDetalle">
            <label class="form-check-label">Tratamiento Sin Detalle</label>
          </div>
          <div>
            <multiselect
              v-model="tipoFactura"
              track-by="nombre"
              label="nombre"
              :options="tipoFacturas"
              placeholder="Seleccione una Opción"
              select-label=""
              deselect-label="X"
              :disabled="notComputarizado"
              :allow-empty="false">
              <span slot="noResult">Sin Resultados</span>
            </multiselect>
          </div>
        </div>
      </div>
    </b-modal>
    <iframe
      name="printer"
      id="printer"
      :src="pdf"
      type="application/pdf"
      width="100%"
      height="770vh"
      style="display: none;"
    >
    </iframe>
  </div>
</template>
<script>
import axios from '@/modules/common/axiosERP';
import Multiselect from 'vue-multiselect';
import downloadPDF, { getPDF } from './downloadpdf';

export const PDF = {
  NOTA: 1,
  FACTURA: 2,
  LIBRODIARIO: 3,
  COMPUTARIZADA_EN_LINEA: 4,
  ELECTRONICA_EN_LINEA: 5,
  URL_SIAT: 6,
};
export default {
  name: 'modalPDF',
  components: {
    Multiselect,
  },
  mounted() {
    // this.tipoFactura = this.tipoFacturas.find(f => f.id > 0);
    this.$parent.$on('resetPrint', this.resetPrint);
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    isFacturacionTercero: {
      type: Boolean,
      default: false,
    },
    isfacturacionTratamiento: {
      type: Boolean,
      default: false,
    },
    optionsPDF: {
      type: Array,
      default() {
        return [];
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
    tipoOrigen: {
      type: String,
      default: '',
    },
    tipoFacturas: {
      type: Array,
      default() {
        return [];
      },
    },
    numeroVenta: {
      type: String,
      default: '',
    },
    numeroTratamiento: {
      type: [Number, String],
      default: '',
    },
  },
  data: () => ({
    types: [
      { id: PDF.NOTA, text: 'NOTA' },
      { id: PDF.FACTURA, text: 'FACTURA' },
      { id: PDF.LIBRODIARIO, text: 'LIBRO DIARIO' },
      { id: PDF.TURNO, text: 'TURNO' },
      { id: PDF.COMPUTARIZADA_EN_LINEA, text: 'Computarizada en linea' },
      { id: PDF.ELECTRONICA_EN_LINEA, text: 'Electronica en linea' },
      { id: PDF.URL_SIAT, text: 'URL SIAT' },
    ],
    clases: [
      { id: PDF.NOTA, text: 'th-custom-color' },
      { id: PDF.FACTURA, text: 'btn-info' },
      { id: PDF.LIBRODIARIO, text: 'th-custom-color' },
      { id: PDF.TURNO, text: 'th-custom-color' },
      { id: PDF.COMPUTARIZADA_EN_LINEA, text: 'px-0 btn-modalidadesEnLinea' },
      { id: PDF.ELECTRONICA_EN_LINEA, text: 'px-0 btn-modalidadesEnLinea' },
      { id: PDF.URL_SIAT, text: 'btn siatColor' },
    ],
    sinLogo: false,
    sinImporte: false,
    tratamientoSinDetalle: false,
    tipoFactura: {},
    notComputarizado: false,
    pdf: null,
  }),
  methods: {
    download(option) {
      if (option.type === this.getUrlSiat) {
        this.showUrlSiat(option.url);
        return;
      }
      const tipoFacturaId = this.tipoOrigen === '' ? '' : this.tipoFactura.id;
      downloadPDF(option.url, this.sinLogo, this.sinImporte, this.tratamientoSinDetalle, tipoFacturaId);
    },
    showUrlSiat(url) {
      axios.post(url)
        .then((response) => {
          const urlSiat = response.data.data.url_siat;
          window.open(urlSiat, '_blank');
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    getTextByType(id) {
      const type = this.types.find((element) => element.id === id);
      return type ? type.text : '';
    },
    getClassByType(id) {
      const clase = this.clases.find((element) => element.id === id);
      if (this.isfacturacionTratamiento && id === PDF.FACTURA) {
        return 'btn-warning';
      }
      return clase ? clase.text : '';
    },
    resetPrint(notComputarizado) {
      this.sinLogo = false;
      this.sinImporte = false;
      this.tratamientoSinDetalle = false;
      this.tipoFactura = this.tipoFacturas.find((f) => f.id > 0);
      this.notComputarizado = notComputarizado;
    },
    showPrinter(response) {
      if (response === null) {
        return;
      }
      const blob = new Blob([response.data], { type: 'application/pdf' });
      this.pdf = URL.createObjectURL(blob);
      window.frames.printer.focus();
      setTimeout(() => {
        window.frames.printer.print();
      }, 500);
    },
    async impresionRapida(url) {
      const tipoFacturaId = this.tipoOrigen === '' ? '' : this.tipoFactura.id;
      this.showPrinter(await getPDF(url,
        this.sinLogo,
        this.sinImporte,
        this.tratamientoSinDetalle, tipoFacturaId));
    },
  },
  watch: {},
  computed: {
    getUrlSiat() {
      return PDF.URL_SIAT;
    },
  },
};
</script>

<style scoped>
  .gris {
    background-color: #BDBDBD;
    color: white;
  }
  .btn-modalidadesEnLinea {
    background-color: #00a8bc;
    color: white;
  }
  .siatColor {
    background-color: #4f8bca;
    color: white;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
